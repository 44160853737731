<template>
  <div class="order-list">
    <error :errors="ordersError" />
    <b-overlay :show="formBusy" rounded="sm">
      <portlet title="بارلىق زاكازلار" body-fit>
        <template v-slot:toolbar>
          <b-row class="align-items-center d-none d-sm-flex">
            <b-col >
              <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            </b-col>
            <b-col >
              <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="end_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            </b-col>
            <b-col :md="start_time && end_time ? 3.5 : 2" :sm="start_time && end_time ? 3.5 : 2" >
              <button v-b-tooltip.hover @click="onClickRefresh"  title="يېڭىلاش" class="btn btn-clean btn-sm mr-2 ">
                يېڭىلاش
              </button>
              <button v-if="start_time && end_time" v-b-tooltip.hover @click="onClickClear"  title="تازلاش" class="btn btn-clean btn-sm ">
                تازلاش
              </button>
            </b-col>
          </b-row>
        </template>
      </portlet>
      <portlet body-fit>
        <template v-slot:body>
          <b-row class="row-no-padding row-col-separator-xl">
            <b-col md="12" lg="12" xl="6">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئۇمۇمىي سانى</h3>
                    <span class="kt-widget1__desc">بارلىق زاكاز سانى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand">{{ orderCounter.total_order ? `${orderCounter.total_order} دانە زاكاز` : ' سېتىلمىغان' }}</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">توپ سېتىلغىنى</h3>
                    <span class="kt-widget1__desc">توپ باھادا سېتىلغان ئەسەر</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand"> {{ orderCounter.total_article_order ? `${orderCounter.total_article_order} دانە زاكاز` : ' سېتىلمىغان' }} </span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">پارچە سېتىلغىنى</h3>
                    <span class="kt-widget1__desc">قىسىم بويىچە سېتىلغان ئەسەر</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand"> {{ orderCounter.total_article_episode_order ? `${orderCounter.total_article_episode_order} دانە زاكاز` : ' سېتىلمىغان' }} </span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">سېتىلغان ئەزالىق</h3>
                    <span class="kt-widget1__desc">بارلىق ئەزالىق زاكازلىرى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand"> {{ orderCounter.total_membership_order ? `${orderCounter.total_membership_order} دانە زاكاز` : ' سېتىلمىغان' }} </span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title"> ئاپتور تارتۇقى </h3>
                    <span class="kt-widget1__desc">بارلىق ئاپتور تارتۇقلىرى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-brand"> {{ orderCounter.total_author_reward_order ? `${orderCounter.total_author_reward_order} دانە زاكاز` : ' سېتىلمىغان' }} </span>
                </div>
                <div v-if="orderCounter.total_entrust_order > 0" class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title"> ئاپتۇماتىك تۇتۇلغان </h3>
                    <span class="kt-widget1__desc">بارلىق ئاپتۇماتىك تۇتۇلغان زاكاز</span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger"> {{ orderCounter.total_entrust_order ? `${orderCounter.total_entrust_order} دانە زاكاز` : ' تۇتۇلمىغان' }} </span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
            <b-col md="12" lg="12" xl="6">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئۇمۇمىي كىرىم</h3>
                    <span class="kt-widget1__desc"> ئاپتور تارتۇقىدىن سىرت بارلىق كىرىم </span>
                  </div>
                  <span class="kt-widget1__number kt-font-success"> {{ orderCounter.total_price ? `${orderCounter.total_price}￥` : 'تېخى سېتىلمىغان' }} </span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">توپ كىرىم</h3>
                    <span class="kt-widget1__desc">توپ سېتىلغان كىرىم</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success"> {{ orderCounter.total_article_price ? `${orderCounter.total_article_price}￥` : 'تېخى سېتىلمىغان' }} </span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">پارچە كىرىم</h3>
                    <span class="kt-widget1__desc">قىسىم بويىچە سېتىلغان كىرىم</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success"> {{ orderCounter.total_article_episode_price ? `${orderCounter.total_article_episode_price}￥` : 'تېخى سېتىلمىغان' }} </span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title"> ئەزالىق كىرىم </h3>
                    <span class="kt-widget1__desc">بارلىق ئەزالىقتىن كىرگەن كىرىم </span>
                  </div>
                  <span class="kt-widget1__number kt-font-success"> {{ orderCounter.total_membership_price ? `${orderCounter.total_membership_price}￥` : 'تېخى سېتىلمىغان' }} </span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title"> ئاپتور تارتۇقى </h3>
                    <span class="kt-widget1__desc"> ئاپتور تارتۇقلىرى سوممىسى، بۇ سۇپا كىرىمى ئەمەس</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success"> {{ orderCounter.total_author_reward_price ? `${orderCounter.total_author_reward_price}￥` : 'تېخى سېتىلمىغان' }} </span>
                </div>
                <div v-if="orderCounter.total_entrust_price > 0" class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title"> ئاپتۇماتىك تۇتۇلغان </h3>
                    <span class="kt-widget1__desc"> ئاپتۇماتىك تۇتۇلغان ھەق </span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger"> {{ orderCounter.total_entrust_price ? `${orderCounter.total_entrust_price}￥` : 'تېخى تۇتۇلمىغان' }} </span>
                </div>
              </div>
              <!--end:: Widgets/Stats2-1 -->
            </b-col>
          </b-row>
        </template>
      </portlet>
      <portlet>
        <template v-slot:body>
          <div class="kt-pagination kt-pagination--brand">
            <div class="kt-pagination__links">
              <b-pagination
                  class="mb-0"
                  v-if="ordersMeta.total"
                  v-model="ordersMeta.current_page"
                  :total-rows="ordersMeta.total"
                  :per-page="ordersMeta.per_page"
                  @change="onChangePage"
              />
            </div>
            <div v-if="ordersMeta.total" class="kt-pagination__toolbar">
              زاكازلار تىزىملىكى
            </div>
            <div v-else class="kt-pagination__toolbar">
              ھازىرچە زاكاز ئۇچۇرى يوق ئىكەن
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col v-for="order in orders" md="3" :key="order.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! order.orderer.avatar}" rounded="" height="50" :src="order.orderer.avatar" alt="image" />
                    <div :class="{'kt-hidden': order.orderer.avatar}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ order.orderer.nickname }}
                    </div>
                  </div>
                  <div class="kt-widget__info overflow-hidden">
                    <a class="kt-widget__username">
                      {{ order.orderer.nickname }}
                    </a>
                    <span class="kt-widget__desc" v-if="order.order_type === 'article'">ئەسەرنى توپ سېتىۋالدى</span>
                    <span class="kt-widget__desc" v-if="order.order_type === 'article_episode'">ئەسەر قىسمىنى پارچە سېتىۋالدى</span>
                    <span class="kt-widget__desc" v-if="order.order_type === 'membership'">ئەزالىق سېتىۋالدى</span>
                    <span class="kt-widget__desc" v-if="order.order_type === 'author_reward'">ئاپتورنى تارتۇقلىدى</span>
                    <span class="kt-widget__desc" v-if="order.order_type === 'entrust'">ئاپتۇماتىك تۇتۇلغان ھەق</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div v-if="['article', 'article_episode'].includes(order.order_type)" class="kt-widget__contact">
                      <span class="kt-widget__label">{{ order.order_type === 'article' ? 'ئەسەر نامى' : 'سېتىۋالغان قىسمى' }}</span>
                      <span class="kt-widget__data">{{ order.orderable.title.substr(0, 40) }}</span>
                    </div>
                    <div v-if="order.order_type === 'membership'" class="kt-widget__contact">
                      <span class="kt-widget__label">ئەزالىق تۈرى</span>
                      <span class="kt-widget__data">{{ order.orderable.title.substr(0, 40) }}</span>
                    </div>
                    <div v-if="order.order_type === 'author_reward'" class="kt-widget__contact">
                      <span class="kt-widget__label">ئاپتور ئىسمى</span>
                      <span class="kt-widget__data">{{ order.orderable.name.substr(0, 40) }}</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">سوممىسى: </span>
                      <span class="kt-widget__data">{{ order.order_total_fee }}</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span v-if="order.order_type === 'entrust'" class="kt-widget__label">پۇل تۇتۇلغان ۋاقتى: </span>
                      <span v-else class="kt-widget__label">پۇل تۆلىگەن ۋاقتى: </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ order.paid_at }} </span>
                    </div>
                    <div v-if="order.order_type === 'entrust'" class="kt-widget__contact">
                      <span  class="kt-widget__label"> كىيىنكى قېتىملىق ۋاقتى: </span>
                      <span class="kt-widget__data" style="direction: ltr">{{ order.orderable.next_time }} </span>
                    </div>
                  </div>
                </div>
                <div v-if="false" class="kt-widget__footer">
                  <b-button type="button" variant="danger" class="btn-upper">پۇل قايتۇرۇش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <!--<portlet title="سېتىۋالغانلار تىزىملىكى">
        <template v-slot:body></template>
      </portlet>-->
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import Portlet from "../../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import orderMixin from "../../../../mixins/orderMixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  export default {
    name: "index",
    mixins: [ formBusyMixin, orderMixin ],
    components: { Error, Portlet, Datetime },
    computed: {
      ...mapGetters(["orders", "ordersMeta", "ordersError"]),
      orderCounter(){
        return this.orderFiveCounter;
      }
    },
    created() {
      this.getOrderList();
      this.handleOrderCounter()
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "زاكاز باشقۇرۇش", route: "list" },
        { title: " بارلىق زاكازلار" },
      ]);
    },
    data(){
      return {
        start_time: '',
        end_time: '',
        orderFiveCounter:{},
        formBusy: false,
      };
    },
    methods: {
      onClickRefresh(){
        let { start_time, end_time } = this;
        if ( ! start_time || ! end_time ){
          this.start_time = '';
          this.end_time = '';
        }
        this.getOrderList(1, this.start_time, this.end_time);
        this.handleOrderCounter();
      },
      onClickClear(){
        this.start_time = '';
        this.end_time = '';
        this.getOrderList(1);
        this.handleOrderCounter();
      },
      onChangePage(page){
        let { start_time, end_time } = this;
        this.getOrderList(page, start_time, end_time);
      },
      handleOrderCounter(){
        this.getOrderCounterList(this.start_time, this.end_time)
          .then(response=>{
            this.orderFiveCounter = response.data;
          })
      }
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
